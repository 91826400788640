<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #status ="scope">
                <template  v-if='(scope.row.userName === "admin" && tenantId !== "1") || (scope.row.userId === user_info[0].userId && tenantId !== "1")'>
                    --
                </template>
                <template v-else>
                    <el-switch v-model="scope.row.status" :inactive-value='0' :active-value='1' @change='changeStatus(scope.row)'/>
                </template>
            </template>  
            <template #caozuo="scope">
                <template  v-if='(scope.row.userName === "admin" && tenantId !== "1") || (scope.row.userId === user_info[0].userId && tenantId !== "1")'>
                    --
                </template>
                <template v-else>
                    <el-button v-if="roleManager_btn_edit" text type="primary"
                        size="small"    @click="openView(1, scope.row)">{{$t('button.update')}} </el-button>
                    <el-button v-if="roleManager_btn_del" text type="primary" size="small"
                        @click="rowDel(scope.row)">{{$t('button.del')}} </el-button>
                </template>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun'/>
            <template #footer>
                <el-button    @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import { questStore } from '@/store/quest'
    import { userStore } from '@/store/user'
    import { tableOption1 } from "@/const/crud/platform/ipInface";
    import { ElNotification } from 'element-plus'
    import fromSearch from '@/components/fromSearch.vue'
    import bindView from './bind.vue'
    import { turnTimeFun } from '@/utils/util.js'
    // import { getDataStyle } from '@/utils/dataBind/parmsView' 

    import mixins from '@/views/mixins/page'
    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['getIpinfoList',['delIpinfoList','whitelistId']])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    const tenantId = questStore().getTenantId
    const user_info = userStore().getUserInfo
    
    const tableOption = ref({});tableOption.value = tableOption1
    const roleManager_btn_add = ref(true)
    const roleManager_btn_edit = ref(true)
    const roleManager_btn_del = ref(true)
    const bindView_ = ref(null)

    import { ipInface } from '@/const/from/platform/ipInface'
    const object_ = ref(ipInface);

    // const valueArray = ref([])
    // getDataStyle('whitelist_type',(e)=>{valueArray.value = e})
    const changeStatus = (e)=>{
        if(e.whitelistId){
            listLoading.value = true
            Api_.updateIpinfoList(e).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e.status === 1?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
                getList()
            })
        }
    }

    const openView = (num,parm)=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm)
        })
    }
    const completeFun = ()=>{
        bindView_.value.completeFun()
    }
    getList(1)
</script>