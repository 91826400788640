<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">

            <el-form-item :label='$t("inface.text3")' prop="tenantId">
                <el-select v-model="fromValue.tenantId" :placeholder="$t('inputTit.xuanze')" :teleported='false' @change='changeGetUserList' :disabled="style_ === 1 || tenantId !== '1'">
                    <el-option :label="item.label" :value="item.value" v-for='item in shopArray' :key = 'item.id'/>
                </el-select>
            </el-form-item>
            <el-form-item :label='$t("switchGoogle.name7")' prop="userId">
                <el-select v-model="fromValue.userId" :placeholder="$t('inputTit.xuanze')" :teleported='false'  :loading="loading_"
                :disabled="!fromValue.tenantId || style_ === 1" placement="bottom-start">
                    <el-option :label="item.username" :value="item.userId" v-for='item in useArray' :key = 'item.userId'
                        :disabled="(tenantId !== '1' && user_info[0].userId === item.userId) || (tenantId !== '1' && item.username === 'admin')"/>
                </el-select>
            </el-form-item>
            <!-- <el-form-item :label='$t("inface.text20")' prop="whitelistType">
                <el-radio-group v-model="fromValue.whitelistType">
                    <el-radio label="1" size="large" border>{{$t('inface.text15_1')}}</el-radio>
                    <el-radio label="2" size="large" border>{{$t('inface.text15_2')}}</el-radio>
                </el-radio-group>
            </el-form-item> -->
            <el-form-item :label='$t("inface.text15")' prop="whitelistText">
                <el-input v-model="fromValue.whitelistText" :placeholder="$t('inface.text21')" type="textarea"/>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import website from '@/config/website'  
    import { getDataStyle } from '@/utils/dataBind/parmsView' 
    import { questStore } from '@/store/quest'
    import { userStore } from '@/store/user'
    const user_info = userStore().getUserInfo

    const fromValue = reactive({ 
        whitelistId: '',tenantId: '',whitelistType:'',
        whitelistText: '',userId:''
    })
    getDataStyle('whitelist_type',(e)=>{fromValue.whitelistType = e[0].value})

    const tenantId = questStore().getTenantId
    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)
    const shopArray = ref([])
    const useArray = ref([])//管理员列表
    const loading_ = ref(false)

    const emit = defineEmits(['successFun'])
    
    const changeGetUserList = ()=>{
        loading_.value = true
        Api_.adminUser({tenantId:fromValue.tenantId,...website.pageConfig}).then((res)=>{
        loading_.value = false
            if(res.code === 0){
                useArray.value = res.data.records
            }
        } )
    }
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.whitelistId
                    if(fromValue.whitelistType === '1' && !fromValue.whitelistText){//打开，必填
                        ElNotification({
                            message: $t('inface.rules1'),
                            type: 'error',
                        })
                    }else{
                        Api_.addIpinfoList(obj_).then(res=>{
                            if(res.code === 0){
                                emit('successFun')
                                ElNotification({
                                    message: $t('alert.succAdd'),
                                    type: 'success',
                                })
                            }else{
                                ElNotification({
                                    message: res.msg,
                                    type: 'error',
                                })
                            }
                        })
                    }
                }else{
                    if(fromValue.whitelistType === '1' && !fromValue.whitelistText){//打开，必填
                        ElNotification({
                            message: $t('inface.rules1'),
                            type: 'error',
                        })
                    }else{
                        Api_.updateIpinfoList(fromValue).then(res=>{
                            if(res.code === 0){
                                emit('successFun')
                                ElNotification({
                                    message: $t('alert.succUpdate'),
                                    type: 'success',
                                })
                            }else{
                                ElNotification({
                                    message: res.msg,
                                    type: 'error',
                                })
                            }
                        })
                    }
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
        }else{
            for(let index in fromValue){
                fromValue[index] = '';
            }
            if(tenantId !== '1'){
                fromValue['tenantId'] = tenantId
            }
            console.log(fromValue)
        }
    }
    Api_.shopList().then(res=>{
        if(res && res.data.records){
            for(let item of res.data.records){
                shopArray.value.push({
                    label: item.name,
                    value: item.id,
                })
            }
            if(tenantId !== '1'){
                fromValue.tenantId = res.data.records[0].id;
                changeGetUserList()
            }
        }
    })
    defineExpose({init_,completeFun})
</script>