
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
export const tableOption1 = {
    border: true,
    stripe: true,
    menuAlign: 'center',
    searchMenuSpan: 6,
    align: 'center',
    viewBtn: false,
    height: 'auto',
    menuWidth: 130,
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    refreshBtn: false,
    menu:false,
    column: [{
        label: t("inface.text3"),
        prop: 'tenantName',
    }, {
        label: t("switchGoogle.name7"),
        prop: 'userName',
    },
    {
        label: t("inface.text20"),
        prop: 'status',
        type: 'radio',
        fixed:'right'
    },{
        label:t("inface.text15"),
        prop: 'whitelistText',
        type: 'textarea',
        span: 24,
    },
    {
        label: t("inface.text16"),
        prop: 'createTime',
    }, 
    {
        label: t("inface.text17"),
        prop: 'updateTime',
    },{
        label: t("inface.text18"),
        prop: 'updateUser',
        editDisplay: false,
        addDisplay: false,
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
      }]
}