import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const ipInface = [{
    type: 'select',
    name: t("inface.text3"),
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    isPlatform: 1,//平台显示，商户不显示
    postData: [],   
    props:{
        label: 'name',
        value: 'id'
    },
    isFartherFun: 'userId',//关联的选项
},{
    type: 'select',
    name: t("switchGoogle.name7"),
    prop: 'userId',
    isPostApi: 'adminUser',
    postURl: '',
    postData: [],   
    props:{
        label: 'username',
        value: 'userId'
    },
    isRelation:'tenantId'//目标对象
},{
    type: 'select',
    name: t("inface.text14"),
    prop: 'whitelistType', 
    isParms: 'whitelist_type'
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]